/*
    This file is the main interface into the whole api
    It gives you the user which is required for all else
    So all else is inside the user and accessed through it
    Since it would be needed anyway
*/

const PASS_AUTH_ITERATIONS = 2500000;

export interface Auth {
  username: string;
  password: string;
}

import DatabaseManager from "./DatabaseManager";
import base64 from "base64-js";
import Server from "./Server";
import { Errors } from "./Types";
import Vue from "*.vue";

export default class User {
  username: string;
  auth: Auth;
  databaseManager: DatabaseManager;
  static vue: Vue;
  static um:
    | { loggedIn: false; user: null }
    | { loggedIn: true; user: User } = { loggedIn: false, user: null };

  private constructor(
    username: string,
    auth: Auth,
    dm: DatabaseManager,
    persist = true
  ) {
    this.username = username;
    this.auth = auth;
    this.databaseManager = dm;
    User.um.loggedIn = true;
    User.um.user = this;
    User.vue.$emit("loaded");
    if (persist) {
      // add to persistant storage!
      const dbr = User.getUserDBR();
      dbr.onsuccess = function () {
        const db = dbr.result;
        const trans = db.transaction("user", "readwrite");
        const os = trans.objectStore("user");
        os.put(
          {
            username: username,
            auth: auth,
            dmMasterKey: dm.masterKey,
          },
          "user"
        );
      };
    }
  }

  /**
   * Generates an IndexedDB database request with a user object store!
   */
  private static getUserDBR(): IDBOpenDBRequest {
    const dbr = indexedDB.open("user", 1);
    dbr.onupgradeneeded = function () {
      dbr.result.createObjectStore("user");
    };
    return dbr;
  }

  /**
   * Checks the persistant IndexedDB store for information and restores it if it exists.
   *
   * This method should be called whenever the website is loaded.
   */
  static loadPersistant(vue: Vue): void {
    this.vue = vue;
    if (User.um.loggedIn) return;
    const dbr = this.getUserDBR();
    dbr.onsuccess = function () {
      const os = dbr.result.transaction("user").objectStore("user");
      const ur = os.get("user");
      ur.onsuccess = async function () {
        const user = ur.result;
        if (!user) {
          vue.$emit("loaded");
          return;
        }
        const dm = await DatabaseManager.getDMWithKey(
          user.auth,
          user.dmMasterKey
        );
        new User(user.username, user.auth, dm, false);
      };
    };
    dbr.onerror = () => vue.$emit("loaded");
  }

  /**
   * Generates user authentication for API server
   * @param username username
   * @param password password
   */
  private static async genAuth(
    username: string,
    password: string
  ): Promise<Auth> {
    // Generate username SHA-256 Hash
    const encoder = new TextEncoder();
    const userBytes = encoder.encode(username);
    let hashBuffer = await crypto.subtle.digest("SHA-256", userBytes);
    const userHash = base64.fromByteArray(new Uint8Array(hashBuffer));

    // Generate password hash
    const userpassBytes = encoder.encode(username + password);
    hashBuffer = await crypto.subtle.digest("SHA-256", userpassBytes);
    const salt = new Uint8Array(hashBuffer).subarray(0, 22);
    const passBytes = encoder.encode(password);
    const passwordKey = await crypto.subtle.importKey(
      "raw",
      passBytes,
      "PBKDF2",
      false,
      ["deriveBits"]
    );
    const passBits = await crypto.subtle.deriveBits(
      {
        name: "PBKDF2",
        hash: "SHA-256",
        salt: salt,
        iterations: PASS_AUTH_ITERATIONS,
      },
      passwordKey,
      256
    );
    const passHash = base64.fromByteArray(new Uint8Array(passBits));

    return { username: userHash, password: passHash };
  }

  /**
   * This creates a new user
   */
  static async create(username: string, password: string): Promise<void> {
    const auth = await this.genAuth(username, password);
    await Server.userCreate(auth);
    const dm = await DatabaseManager.getDM(auth, password);
    new this(username, auth, dm);
  }

  /**
   * This logins a user
   */
  static async login(username: string, password: string): Promise<void> {
    const auth = await this.genAuth(username, password);
    await Server.userVerify(auth);
    const dm = await DatabaseManager.getDM(auth, password);
    new this(username, auth, dm);
  }

  /**
   * this logs a user out
   */
  async logout(): Promise<void> {
    User.um.user = null;
    User.um.loggedIn = false;
    // clear persistant storage
    const dbr = User.getUserDBR();
    dbr.onsuccess = function () {
      dbr.result.transaction("user", "readwrite").objectStore("user").clear();
    };
  }

  async checkPassword(password: string): Promise<boolean> {
    const auth = await User.genAuth(this.username, password);
    return (
      this.auth.username === auth.username &&
      this.auth.password === auth.password
    );
  }

  /**
   * This changes the user's username
   */
  async changeUsername(newUsername: string, password: string): Promise<void> {
    if (!(await this.checkPassword(password))) {
      throw Errors.PasswordDoesNotMatch;
    }
    const newAuth = await User.genAuth(newUsername, password);
    await Server.userChangeUsername(this.auth, newAuth);
    this.auth = newAuth;
    this.username = newUsername;
    await this.databaseManager.changeAuth(newAuth, password);
  }

  /**
   * This changes the user's password
   * don't forget to include the database manager, it will want to know
   */
  async changePassword(newPassword: string): Promise<void> {
    const newAuth = await User.genAuth(this.username, newPassword);
    await Server.userChangePass(this.auth, newAuth.password);
    await this.databaseManager.changeAuth(newAuth, newPassword);
    this.auth = newAuth;
  }

  /**
   * This deletes a user
   * database manager need not to know, he won't remember anything!
   */
  async deleteUser(): Promise<void> {
    await Server.userDelete(this.auth);
    this.logout();
  }
}
