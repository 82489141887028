/*
    This provides access to all databases that a user
    knows. 
    This only works while online, in the future we'll figure out an offline solution.

        In the future The offline database will save all instructions and
        when you reload the page and it can connect to the server successfully,
        it will run those commands on the online database to restore it to where we are.

    maybe in the future I'll add a change keys function to make it more secure.
*/

import { SqlJsStatic, Database } from "sql.js";
import { Auth } from "./User";
import Server from "./Server";
import { Errors } from "./Types";
import initSqlJs from "sql.js";

const DBI_ENCRYPT_VERSION = 1;
const DBI_VERSION = 1;
const DBI_PBKDF2_ITERATIONS = 2500000;

let SQL: SqlJsStatic;

async function init() {
  SQL = await initSqlJs({
    locateFile: (file) => `/js/${file}`,
  });
}

/* -------------------dbindex file format (before encryption)------------
WHAT                SIZE(bytes)
version number=1    1
#databases          1
length of name      #databases
name of database    length_of_name
keys                #databases*32

-------------------dbindex file encryption format----------------------
WHAT            SIZE(bytes)
version         1
salt            32
iterations      4
iv              12
data            ?

----------database file format----------
WHAT  SIZE
iv    12
data  ?
*/

type Key = {
  key: CryptoKey;
  salt: Uint8Array;
  iterations: number;
};
type EDBI = {
  salt: Uint8Array;
  iv: Uint8Array;
  iterations: number;
  data: Uint8Array;
};

export default class DatabaseManager {
  private userAuth: Auth;
  masterKey: Key;
  names: string[]; // The names of all the databases the user has.
  cryptoKeys: CryptoKey[]; // The keys to the different databases
  database: Database | undefined; // current open database
  name: string | undefined; // name of the currently open database

  constructor(
    auth: Auth,
    masterkey: Key,
    names: string[],
    cryptoKeys: CryptoKey[]
  ) {
    this.userAuth = auth;
    this.masterKey = masterkey;
    this.names = names;
    this.cryptoKeys = cryptoKeys;
  }

  // Create the Database manager with just a user and password!
  static async getDM(
    userAuth: Auth,
    password: string
  ): Promise<DatabaseManager> {
    try {
      const edbi = await this.getAndDeserializeDBI(userAuth);
      // generate masterkey
      const passwordBytes = new TextEncoder().encode(password);
      const passwordKey = await crypto.subtle.importKey(
        "raw",
        passwordBytes,
        "PBKDF2",
        false,
        ["deriveKey"]
      );
      const masterKey = await crypto.subtle.deriveKey(
        {
          name: "PBKDF2",
          salt: edbi.salt,
          iterations: edbi.iterations,
          hash: "SHA-256",
        },
        passwordKey,
        { name: "AES-GCM", length: 256 },
        false,
        ["encrypt", "decrypt"]
      );

      // decrypt the dbindex file and generate a DatabaseManager!
      return await this.decryptDM(userAuth, edbi, {
        key: masterKey,
        salt: edbi.salt,
        iterations: edbi.iterations,
      });
    } catch (e) {
      if (e === Errors.APIDataDoesNotExist) {
        // dbindex hasn't been created yet on the server.
        // Generate new Masterkey
        const salt = crypto.getRandomValues(new Uint8Array(32));
        const passwordBytes = new TextEncoder().encode(password);
        const passwordKey = await crypto.subtle.importKey(
          "raw",
          passwordBytes,
          "PBKDF2",
          false,
          ["deriveKey"]
        );
        const masterKey = await crypto.subtle.deriveKey(
          {
            name: "PBKDF2",
            salt: salt,
            iterations: DBI_PBKDF2_ITERATIONS,
            hash: "SHA-256",
          },
          passwordKey,
          { name: "AES-GCM", length: 256 },
          false,
          ["encrypt", "decrypt"]
        );

        return new this(
          userAuth,
          { key: masterKey, salt: salt, iterations: DBI_PBKDF2_ITERATIONS },
          [],
          []
        );
      } else {
        console.error(e);
        throw e;
      }
    }
  }

  // Create the Database manager with just a user auth and the master key!
  static async getDMWithKey(
    userAuth: Auth,
    masterKey: Key
  ): Promise<DatabaseManager> {
    const edbi = await this.getAndDeserializeDBI(userAuth);
    return await this.decryptDM(userAuth, edbi, masterKey);
  }

  /**
   * Gets the encrypted deserialized copy of dbindex file
   *
   * @param userAuth The Auth data for the user
   * @returns An EDBI Obeject with the deserialized encrypted data
   */
  static async getAndDeserializeDBI(userAuth: Auth): Promise<EDBI> {
    // Get the encrypted dbindex file
    const dbindexdata = await Server.dataGet(userAuth, "dbindex");

    // deserialize dbindexfile data
    let pos = 0;
    if (dbindexdata[pos++] !== DBI_ENCRYPT_VERSION) {
      throw Errors.DBIUnknownEncryptVersion;
    }
    const salt = dbindexdata.subarray(pos, (pos += 32));
    const iterations = new Uint32Array(
      dbindexdata.buffer.slice(pos, (pos += 4))
    )[0];
    const iv = dbindexdata.subarray(pos, (pos += 12));
    const data = dbindexdata.subarray(pos);
    return { salt, iterations, iv, data };
  }

  /**
   *
   * @param userAuth AUTH User Authentication
   * @param edbi EDBI encrypted dbindex file that is deserialized
   * @param masterKey KEY that is the master key
   * @returns DatabaseManager!
   */
  static async decryptDM(
    userAuth: Auth,
    edbi: EDBI,
    masterKey: Key
  ): Promise<DatabaseManager> {
    // decrypt dbindex file
    const dbindex = new Uint8Array(
      await crypto.subtle.decrypt(
        { name: "AES-GCM", iv: edbi.iv },
        masterKey.key,
        edbi.data
      )
    );

    // deserialize the dbindex file
    let pos = 0;
    if (dbindex[pos++] !== DBI_VERSION) {
      throw Errors.DBIUnknownVersion;
    }
    const numDatabases = dbindex[pos++];

    // Get names
    const names: string[] = [];
    const decoder = new TextDecoder();
    pos += numDatabases;
    for (let i = 0; i < numDatabases; i++) {
      names.push(
        decoder.decode(dbindex.subarray(pos, (pos += dbindex[i + 2])))
      );
    }

    // Get CryptoKeys
    const promiseKeys = [];
    for (let i = 0; i < numDatabases; i++) {
      const array = dbindex.subarray(pos, (pos += 32));
      promiseKeys.push(
        crypto.subtle.importKey("raw", array, "AES-GCM", true, [
          "encrypt",
          "decrypt",
        ])
      );
    }
    const keys = await Promise.all(promiseKeys);
    return new this(userAuth, masterKey, names, keys);
  }

  /**
   * Change the encryption of the databases for when the auth changes so the user doesn't get locked out!
   * @param auth the new authentication
   * @param password the new password or same one if just the username changed
   */
  async changeAuth(auth: Auth, password: string): Promise<void> {
    // Generate new MasterKey
    this.userAuth = auth;
    const salt = crypto.getRandomValues(new Uint8Array(32));
    const passBytes = new TextEncoder().encode(password);
    const passKey = await crypto.subtle.importKey(
      "raw",
      passBytes,
      "PBKDF2",
      false,
      ["deriveKey"]
    );
    const masterKey = await crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: DBI_PBKDF2_ITERATIONS,
        hash: "SHA-256",
      },
      passKey,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
    this.masterKey = {
      key: masterKey,
      salt: salt,
      iterations: DBI_PBKDF2_ITERATIONS,
    };
    // Update the dbindex on the server with the new encryption!!! Important!!!
    await this.saveDBI();
  }

  /*
        Save the database manager into a dbindex file on the API server!
    */
  async saveDBI(): Promise<void> {
    // Create the dbindex file
    const encoder = new TextEncoder();
    const names: Uint8Array[] = [];
    let totalLengthOfNames = 0;
    for (const name of this.names) {
      const array = encoder.encode(name);
      names.push(array);
      totalLengthOfNames += array.length;
    }
    const numDatabases = this.names.length;
    const dbindex = new Uint8Array(2 + numDatabases * 33 + totalLengthOfNames);
    dbindex[0] = DBI_VERSION;
    dbindex[1] = numDatabases;
    // add names
    let pos = 2 + numDatabases;
    for (let i = 0; i < numDatabases; i++) {
      dbindex[2 + i] = names[i].length;
      dbindex.set(names[i], pos);
      pos += names[i].length;
    }
    // add CryptoKeys
    const promiseKeys = [];
    for (const key of this.cryptoKeys) {
      promiseKeys.push(crypto.subtle.exportKey("raw", key));
    }
    const keys = await Promise.all(promiseKeys);
    for (const buffer of keys) {
      dbindex.set(new Uint8Array(buffer), pos);
      pos += 32;
    }

    // Encrypt dbindex file
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const dbindexdata = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv: iv },
      this.masterKey.key,
      dbindex
    );

    // Serialize the encrypted data with required info!
    const data = new Uint8Array(49 + dbindexdata.byteLength);
    pos = 0;
    data[pos++] = DBI_ENCRYPT_VERSION;
    data.set(this.masterKey.salt, pos);
    pos += 32;
    const iterarray = new Uint32Array(1);
    iterarray[0] = this.masterKey.iterations;
    data.set(new Uint8Array(iterarray.buffer), pos);
    pos += 4;
    data.set(iv, pos);
    pos += 12;
    data.set(new Uint8Array(dbindexdata), pos);

    // Send the data to the server
    await Server.dataUpload(this.userAuth, "dbindex", data);
  }

  /* 
        opens a database or switches to the new database
        Right now it only opens a remote database
        until I can figure out the best way to enable 
        an offline database at the same time.
    */
  async openDatabase(name: string): Promise<void> {
    if (!SQL) await init();
    if (this.name && this.database) {
      if (this.name === name) return; // The database is already open
      await this.saveDatabase();
      this.database.close();
    }
    const index = this.names.indexOf(name);
    if (index === -1) {
      // database doesn't exist!
      this.names.push(name);
      this.cryptoKeys.push(
        await crypto.subtle.generateKey(
          { name: "AES-GCM", length: 256 },
          true,
          ["encrypt", "decrypt"]
        )
      );
      this.saveDBI();
      this.database = new SQL.Database();
      this.name = name;
      await this.saveDatabase();
      return;
    }
    // Get Database from server
    const encryptedDatabase = await Server.dataGet(this.userAuth, name);
    // Deserialize then decrypt encrypted database
    const iv = encryptedDatabase.subarray(0, 12);
    const data = encryptedDatabase.subarray(12);
    const decryptedDatabase = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      this.cryptoKeys[index],
      data
    );
    this.database = new SQL.Database(new Uint8Array(decryptedDatabase));
    this.name = name;
  }

  /*
        Saves the open database and uploads it to the API server
    */
  async saveDatabase(): Promise<void> {
    if (!this.name || !this.database) {
      throw Errors.NoDatabaseOpen;
    }
    const index = this.names.indexOf(this.name);
    const data = this.database.export();
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv: iv },
      this.cryptoKeys[index],
      data
    );
    const encryptedDatabase = new Uint8Array(12 + encryptedData.byteLength);
    encryptedDatabase.set(iv);
    encryptedDatabase.set(new Uint8Array(encryptedData), 12);
    await Server.dataUpload(this.userAuth, this.name, encryptedDatabase);
  }
}
