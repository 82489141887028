








import { Component, Vue, Watch } from "vue-property-decorator";
import User from "@/api/User";
import LoginForm from "../components/LoginForm.vue";

Vue.component("LoginForm", LoginForm);

@Component
export default class Login extends Vue {
  private UM = User.um;
  @Watch("UM.loggedIn")
  onUserChanged(newVal: boolean): void {
    if (newVal) {
      this.redirect();
    }
  }

  redirect(): void {
    if (this.$route.query["from"])
      this.$router.push(this.$route.query["from"] as string);
    else this.$router.push("/");
  }

  created(): void {
    if (this.UM.loggedIn) {
      this.redirect();
    }
  }
}
