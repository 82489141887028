











import { Component, Vue } from "vue-property-decorator";
import User from "@/api/User";
import error from "@/api/errors";

@Component
export default class LoginForm extends Vue {
  private showError = error.bind(this, this);
  loading = false;
  username = "";
  password = "";

  async submit(evt: Event): Promise<void> {
    evt.preventDefault();
    this.loading = true;
    try {
      await User.login(this.username, this.password);
      this.username = "";
      this.password = "";
    } catch (e) {
      this.$bvToast.toast(e, {
        variant: "danger",
        title: "Error logging in!",
        toaster: "b-toaster-top-center",
      });
    } finally {
      this.loading = false;
    }
  }
}
