/*
    Provides mappings to all commands on the API Server!
*/

import { Auth } from "./User";
import { Errors, ErrorMessages as EM } from "./Types";
import base64 from "base64-js";

export default abstract class Server {
  // Upload data to server
  // If it doesn't throw an error, data was uploaded successfully!
  static async dataUpload(
    auth: Auth,
    name: string,
    data: Uint8Array
  ): Promise<void> {
    await this.send(`data/${name}/upload`, {
      username: auth.username,
      password: auth.password,
      data: base64.fromByteArray(data),
    });
  }

  // Get Data from server
  static async dataGet(auth: Auth, name: string): Promise<Uint8Array> {
    const response = await this.send(`data/${name}/get`, {
      username: auth.username,
      password: auth.password,
    });
    return base64.toByteArray(response.data);
  }

  // Create a user
  // If it doesn't throw an error, you are good to go!
  static async userCreate(auth: Auth): Promise<void> {
    await this.send("user/create", {
      username: auth.username,
      password: auth.password,
    });
  }

  // Verify a user
  // If it doesn't throw an error, you are good to go!
  static async userVerify(auth: Auth): Promise<void> {
    await this.send("user/verify", {
      username: auth.username,
      password: auth.password,
    });
  }

  // Delete a user
  // If it doesn't throw an error, user was deleted!
  static async userDelete(auth: Auth): Promise<void> {
    await this.send("user/delete", {
      username: auth.username,
      password: auth.password,
    });
  }

  /*
        Change password
        newPassword must be a PBKDF Hash!!!!
        If it doesn't throw an error, the password was changed
    */
  static async userChangePass(auth: Auth, newPassword: string): Promise<void> {
    await this.send("user/changePass", {
      username: auth.username,
      password: auth.password,
      newpass: newPassword,
    });
  }

  // Change username
  // Must provide all new Auth!!!
  // If it doesn't throw an error, the Username was changed!
  static async userChangeUsername(auth: Auth, newAuth: Auth): Promise<void> {
    await this.send("user/changeUsername", {
      username: auth.username,
      password: auth.password,
      newuser: newAuth.username,
      newpass: newAuth.password,
    });
  }

  // Internal method for communicating with the API server
  private static async send(address: string, data: object): Promise<any> { // eslint-disable-line
    try {
      const response = await fetch(
        process.env.VUE_APP_API_URL + "/" + address,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.status === 204) throw Errors.APIDataDoesNotExist;
      if (response.ok) {
        return await response.json();
      }
      const message = await response.json();
      throw { message: "badResponse", data: message };
    } catch (e) {
      if (e === Errors.APIDataDoesNotExist) throw e;
      // Unable to talk to the api server for some reason...
      if (e.message === "Failed to fetch") {
        EM.Messages.set(Errors.APIUnReachable, e);
        throw Errors.APIUnReachable;
      }
      if (e.message === "badResponse") {
        // Check for all possible error codes
        // And return the correct error
        const message = e.data;
        if (message.code) {
          switch (message.code) {
            case 5:
              EM.Messages.set(Errors.APIDataDoesNotExist, message);
              throw Errors.APIDataDoesNotExist;
            case 3:
              EM.Messages.set(Errors.APIUsernameTaken, message);
              throw Errors.APIUsernameTaken;
            case 1:
              EM.Messages.set(Errors.APIUsernameNonExistant, message);
              throw Errors.APIUsernameNonExistant;
            case 2:
              EM.Messages.set(Errors.APIIncorrectPassword, message);
              throw Errors.APIIncorrectPassword;
          }
        }
        EM.Messages.set(Errors.APIUnknownResponse, message);
        throw Errors.APIUnknownResponse;
      }
      // I really have no idea what could cause this...
      EM.Messages.set(Errors.IntenalError, e);
      throw Errors.IntenalError;
    }
  }
}
