






// @ is an alias to /src
import User from "@/api/User";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Database } from "sql.js"; // eslint-disable-line

@Component
export default class Home extends Vue {
  private UM = User.um;
  private fields = {
    username: "",
    password: "",
  };
  private error: string | null = null;
  private online: boolean;
  private table = [{ database: "", not: "", loaded: "" }];
  private loading = false;

  constructor() {
    super();
    this.online = navigator.onLine;
    window.addEventListener("online", () => {
      this.online = true;
    });
    window.addEventListener("offline", () => {
      this.online = false;
    });
  }

  // async test() {
  //	 const dostuff = (database: DataBase) => {
  //		 const res = database.db.exec(
  //			 "SELECT name FROM sqlite_master WHERE type='table' AND name='home'"
  //		 );
  //		 if (res.length == 0) {
  //			 // Need to create the table
  //			 console.log("Creating the table");
  //			 database.db.run(
  //				 "CREATE TABLE home (a int, b char);" +
  //					 "INSERT INTO home VALUES (0, 'hello');" +
  //					 "INSERT INTO home VALUES (1, 'world');"
  //			 );
  //			 database.save();
  //		 } else {
  //			 // Need to show the table
  //			 console.log("Using the saved version of the table");
  //		 }
  //		 const res2 = database.db.exec("SELECT * FROM home;");
  //		 this.table = res2 as any; // eslint-disable-line
  //	 };
  //	 const initiate = async () => {
  //		 try {
  //			 const db = await DataBase.get(this.user!, "home"); // eslint-disable-line
  //			 dostuff(db);
  //		 } catch (e) {
  //			 if (e.code == 998) {
  //				 const pass = prompt("Please enter your password", "password");
  //				 if (pass != null) {
  //					 if (!(await this.user!.checkPassword(pass))) { // eslint-disable-line
  //						 this.error = "Incorrect Password, unable to open database home1";
  //						 return;
  //					 }
  //					 try {
  //						 const db = await DataBase.get(this.user!, "home", pass); // eslint-disable-line
  //						 dostuff(db);
  //						 return;
  //					 } catch (e) {
  //						 if (e.code == 997) {
  //							 this.error =
  //								 "Incorrect Password, unable to open database home2";
  //							 return;
  //						 }
  //					 }
  //				 } else {
  //					 this.error = "Test DataBase Cancelled!";
  //					 return;
  //				 }
  //			 }
  //			 this.error = "Unkown Error!";
  //			 console.error(e);
  //		 }
  //	 };
  //	 this.loading = true;
  //	 await initiate();
  //	 this.loading = false;
  // }

  @Watch("error")
  private async watchError(newError: string) {
    if (newError !== null) {
      setTimeout(() => {
        this.error = null;
      }, 5000);
    }
  }
}
