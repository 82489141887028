export const enum Errors {
  APIUnReachable = "Error connecting to API Server!",
  IntenalError = "Internal Error!",
  APIUnknownResponse = "Unknown Error recieved from API!",
  APIDataDoesNotExist = "APIDataDoesNotExist",
  APIUsernameTaken = "Username already in use!",
  APIUsernameNonExistant = "Username does not exist!",
  APIIncorrectPassword = "Incorrect Password!",
  DBIUnknownVersion = "DBIUnknownVersion",
  DBIUnknownEncryptVersion = "DBIUnknownEncryptVersion",
  NoDatabaseOpen = "NoDatabaseOpen",
  PasswordDoesNotMatch = "Password is not correct",
}

export abstract class ErrorMessages {
  static Messages = new Map<string, any>(); // eslint-disable-line
}
