import { VNode } from "vue";

export default function error(
  parent: Vue,
  message: string,
  title?: string
): void {
  const h = parent.$createElement;

  let tit: string | VNode = "";
  if (!title) {
    tit = h("div", {}, [
      h("b-spinner", { props: { type: "grow", small: true } }),
      " Error ! ",
      h("b-spinner", { props: { type: "grow", small: true } }),
    ]);
  } else {
    tit = title;
  }

  parent.$bvToast.toast(message, {
    variant: "danger",
    solid: true,
    toaster: "b-toaster-top-center",
    title: tit,
  });
}
