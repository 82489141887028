










































import { Component, Vue } from "vue-property-decorator";
import User from "@/api/User";
import LoginForm from "./components/LoginForm.vue";
import error from "@/api/errors";

Vue.component("LoginForm", LoginForm);

@Component
export default class App extends Vue {
  private loaded = false;
  private UM = User.um;
  private username = "";
  private password = "";
  private create = {
    username: "",
    password: "",
    cpassword: "",
  };
  private createOverlay = false;
  private showError = error.bind(this, this);

  created(): void {
    this.$on("loaded", () => {
      this.loaded = true;
    });
    User.loadPersistant(this);
  }

  private async onCreate(evt: Event) {
    evt.preventDefault();
    this.createOverlay = true;
    let validateError = false;
    if (this.create.username.length < 3) {
      this.showError("Username must be at least 3 characters long!");
      validateError = true;
    }
    if (this.create.password.length < 8) {
      this.showError("Password must be at least 8 characters long!");
      validateError = true;
    }
    if (this.create.cpassword != this.create.password) {
      this.showError("Passwords must match!");
      validateError = true;
    }
    if (validateError) {
      this.createOverlay = false;
      return;
    }
    try {
      await User.create(this.create.username, this.create.password);
      this.$bvToast.toast("Account Created Successfully, Welcome!", {
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-center",
        title: "Welcome",
      });
    } catch (e) {
      this.showError("Internal Error!", "Internal Error!");
      this.createOverlay = false;
      return;
    }

    this.$bvModal.hide("create-modal");
    this.createOverlay = false;
  }

  private async onLogout() {
    if (this.UM.loggedIn) {
      this.UM.user.logout();
    }
  }
}
